<template>
    <div class="totalBox">
        <jy-query ref="checkForm" :model="formInline" label-width="90px">
            <jy-query-item label="查询类型" prop="type">
                <el-radio-group v-model="type">
                    <el-radio label="0">线路</el-radio>
                    <el-radio label="1">车辆</el-radio>
                  </el-radio-group>
            </jy-query-item>
            <jy-query-item prop="routeId" label="线路" >
                <el-select v-model="routeIds" multiple @change="routeChange">
                    <el-option v-for="one in routeList" v-bind:key="one.routeId" :label="one.cName" :value="one.routeId"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item prop="customNo" label="自编号" v-if="type == '1'&&isshow">
                <el-input v-model="formInline.customNo"></el-input>
            </jy-query-item>
            <jy-query-item label="查询方式" prop="queryType">
                <el-radio-group v-model="formInline.queryType">
                    <el-radio label="1">月</el-radio>
                    <el-radio label="0">年</el-radio>
                  </el-radio-group>
            </jy-query-item>
            <jy-query-item label="查询年" prop="queryYear" v-if="formInline.queryType == '0'&&isshow">
                <el-date-picker v-model="formInline.queryYear" :clearable="false" type="year" value-format="yyyy"  placeholder="选择年"></el-date-picker>
            </jy-query-item>
            <jy-query-item label="查询月" prop="queryMonth"  v-if="formInline.queryType == '1'&&isshow">
                <el-date-picker v-model="formInline.queryMonth" :clearable="false" type="month" value-format="yyyy-MM"  placeholder="选择月"></el-date-picker>
            </jy-query-item>
            <jy-query-item label="选择车辆" prop="vehicleIds" :span="2" v-if="type == '1'&&isshow">
                <el-input v-model="formInline.vehicleNames" placeholder="选择车辆" readonly @focus="chooseCar"></el-input>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" v-if="btnexist('list')||true">查询</el-button>
                <el-button type="primary" @click="resetForm('checkForm')" v-if="btnexist('reset')||true">重置</el-button>
                <el-row>
                    <el-button type="primary" @click="onexport"  v-if="btnexist('export')">导出</el-button>
                </el-row>
            </template>
        </jy-query>
        <jy-table ref="jyTable" :data="dataList">
            <jy-table-column type="index" label="序号" width="60" fixed></jy-table-column>
            <jy-table-column prop="routeNa" label="线路名称" min-width="100" fixed v-if="type == '0'"></jy-table-column>
            <jy-table-column prop="routeNa" label="所属线路" min-width="100" fixed v-if="type == '1'"></jy-table-column>
            <jy-table-column prop="customNo" label="自编号" min-width="100" fixed v-if="type == '1'"></jy-table-column>
            <jy-table-column prop="vehicleNo" label="车牌号" min-width="100" fixed v-if="type == '1'"></jy-table-column>
            <jy-table-column prop="totalMil" label="总里程(km)" min-width="100" fixed></jy-table-column>
            <jy-table-column :prop="item" :label="item" min-width="70" v-for="item in propList" :key="item"></jy-table-column>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
        <car-selectbatch ref="carSelectbatch" @choose="chooseCarList"></car-selectbatch>
    </div>
</template>
<script>
import carSelectbatch from '@/components/pages/admin/resourcesAllocation/carDriver/carSelectbatch'
import {btnMixins} from '@/common/js/button.mixin'
export default {
    data() {
        return {
            isshow:true,
            formInline: {
                routeId:'',
                customNo: '',
                vehicleIds: '',
                vehicleNames: '',
                queryType:'1',
                queryYear: '',
                queryMonth:'',
            },
            type:'0',
            pageSize: 10,
            total: 100,
            pageIndex: 1,
            dataList: [],
            routeList: [],
            carList:[],
            propList:[],
            routeIds:[],

            btnMenuId:'scheduleStatisticalAnalysisGPStotal',
        }
    },
    watch: {
        'formInline.queryType'(val) {
            console.log(val)
            this.isshow = false
            this.$nextTick(()=>{
                this.isshow = true
            })
        },
    },
    mixins:[btnMixins],
    components: {
        carSelectbatch,
    },
    activated() {
    },
    async created() {
        this.type = '0';
        this.getNowData()
        await this.getRouteList()
        this.getList()
    },

    methods: {
        getNowData(){
            let date = new Date();
            let year = date.getFullYear()
            let month = date.getMonth()
            if (month == 0) {
                year -= 1
                month = 12
            }
            month = month < 10 ? ('0' + month) : month
            this.formInline.queryYear = new Date().Format('yyyy')
            this.formInline.queryMonth = year + '-' + month
        },
        async getRouteList() {
            let url = '/route/info/queryRouteInfoByOrgIds'
            await this.$http.post(url).then(({ detail }) => {
                this.routeList = detail
                // this.formInline.routeId = this.routeList[0].routeId
                // if(this.routeList.length){
                //     this.formInline.routeId = this.routeList[0].routeId
                // }
            })
        },
        getList() {
            let url = '/stat/gps/mileage/queryRouteByMonth'
            if(this.type == '1') {
                url = '/stat/gps/mileage/queryByMonth'
            }
            let option = {
                ...this.formInline,
                pageSize: this.pageSize,
                pageIndex: this.pageIndex
            }
            if (option.queryType == 1) {
                option.date = option.queryMonth
            }else{
                url = '/stat/gps/mileage/queryRouteByYear'
                if(this.type == '1') {
                    url ='/stat/gps/mileage/queryByYear'
                }
                option.date = option.queryYear
            }
            Object.keys(option).forEach(key=>{
                if(/^\s*$/.test(option[key]) || option[key]===undefined){
                    delete option[key]
                }
            })
            this.$http.post(url, option).then(({ detail }) => {
                this.dataList = detail.list
                console.log(this.dataList)
                if(this.dataList.length){
                    this.propList = this.dataList[0].statGpsMileageDetailVOList.map(item=>{
                        let list = item.date.split('-')
                        if(option.queryType == 1){
                            return list[list.length-1]
                        }else{
                            return list[list.length-1]
                        }
                    })
                    this.propList.sort((a,b)=>{
                        return Number(a) - Number(b)
                    })
                    this.propList = this.propList.map(item=>{
                        if(option.queryType == 1){
                            return item+'号'
                        }else{
                            return item+'月'
                        }
                    })
                }else{
                    this.propList = []
                }
                this.dataList.forEach(item=>{
                    item.statGpsMileageDetailVOList.forEach(one=>{
                        let list = one.date.split('-')
                        let prop = ''
                        if(option.queryType == 1){
                            prop = list[list.length-1]+'号'
                        }else{
                            prop = list[list.length-1]+'月'
                        }
                        item[prop] = one.mil
                    })
                })
                this.total = detail.total
            })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getList()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.carList = []
            this.formInline.vehicleNames = ''
            this.type = '0'
            this.formInline.routeId = ''
            this.formInline.queryType = '1'
            this.formInline.customNo = ''
            this.routeIds = []
            // if(this.routeList.length){
            //     this.formInline.routeId = this.routeList[0].routeId
            // }
            this.getNowData()
            this.oncheck()
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
        routeChange() {
            this.formInline.routeId = this.routeIds.toString()
        },
        chooseCar(){
            this.$refs.carSelectbatch.init(this.carList)
        },
        chooseCarList(list){
            this.carList = list
            this.formInline.vehicleNames = list.map(item=>item.plateNum).join(',')
            this.formInline.vehicleIds = list.map(item=>item.vehicleId).join(',')
        },
        //导出
        onexport(){
            let url = '/stat/gpsMileage/export'
            let option = {
                ...this.formInline,
            }
            option.queryMonth = option.queryMonth+'-01'
            this.$http.post(url, option, { type: 'format', isExcel: true }).then(res => {
                this.$util.funDownload(res.data, 'GPS里程统计')
            })
        },
    }
}

</script>
<style lang="scss">
</style>
